//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2021 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See docs/COPYRIGHT.rdoc for more details.
//++

#login-form
  margin: 50px auto 0
  padding-top: 20px
  width: 511px
  word-break: break-word

  .login-options-container
    margin-bottom: 10px
    .login-links
      float: right
      text-align: right
      margin-top: -3rem

#content .login-auth-providers.wide
  width: auto
  text-align: center

  a.auth-provider
    float: none
    display: inline-block

// use id selectors to be specific enough to override general content and top-menu definitions
#content .login-auth-providers, #top-menu #nav-login-content .login-auth-providers,
.modal-wrapper .login-auth-providers
  width: 471px
  margin-top: 25px

  h3
    border: none
    margin-top: 20px
    font-weight: normal
    font-size: 1rem
    text-decoration: none
    text-align: center

    position: relative
    z-index: 1

    // line behind title
    // from http://codepen.io/ericrasch/pen/Irlpm
    &:before
      border-top: 2px solid var(--content-form-separator-color)
      content: ""
      // this centers the line to the full width specified
      margin: 0 auto
      // positioning must be absolute here, and relative positioning
      //  must be applied to the parent
      position: absolute
      top: 50%
      left: 0
      right: 0
      bottom: 0
      width: 95%
      z-index: -1

    // also line behind title
    span
      // to hide the lines from behind the text, you have to set the
      // background color the same as the container
      background: var(--content-form-bg-color)
      padding: 0 15px

  .login-auth-provider-list
    @include prevent-float-collapse

  a.auth-provider
    float: left
    padding: 0.5rem 0.5rem 0.5rem 2rem
    margin-left: 10px
    margin-bottom: 10px
    background-size: 20px 20px
    background-repeat: no-repeat
    background-position: 5px center
    background-color: light-grey
    font-weight: normal
    color: var(--body-font-color)

    &:hover
      text-decoration: none

    .auth-provider-name
      line-height: normal
      white-space: normal

.modal-wrapper .login-auth-providers h3 span
  background: var(--header-drop-down-bg-color)

#top-menu #nav-login-content .login-auth-providers
  h3
    &:before
      width: 100%
    span
      background: var(--header-drop-down-bg-color)
  .login-auth-provider-list
    margin-top: -15px
    margin-bottom: 10px

#top-menu #nav-login-content .login-auth-providers.no-pwd
  margin-top: 0

.registration-footer
  display: block
  margin-top: 2em
