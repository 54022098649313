/* -- copyright
// OpenProject My Project Page Plugin
//
// Copyright (C) 2011-2015 the OpenProject Foundation (OPF)
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See doc/COPYRIGHT.md for more details.

// Defined by Google
// see: https://developers.google.com/identity/branding-guidelines
// !important is necessary to overwrite the default styling options
#top-menu #nav-login-content .login-auth-providers a.auth-provider.auth-provider-google,
#content .login-auth-providers a.auth-provider-google,
.modal-wrapper .login-auth-providers a.auth-provider-google
  padding: 8px 8px 8px 50px
  background-position-x: 8px
  background-size: 18px 18px
  background-color: #FFF
